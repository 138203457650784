#wrapper{
    position: absolute;
    top: 20vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
#homie{
    position: absolute;
    max-height: 6vh;
    top: 14vh;
    left: 30vw;
}
#content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3vw;
    border: 2px solid black;
    border-radius: 2%;
}
.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 42vw;
    text-align: center;
    border: 2px solid rgba(255,255,0,.8);
    border-radius: 5%;
    margin-top: 10px;
    padding: 5px;
    background-color: gainsboro;
}
.contact h2{
    width: 21vw;
    text-decoration: none;
    color: black;
}
#content h1{
    color: black;
    font-size: 10vh;
    text-shadow: -2px 2px 0 darkgray;
    font-family: 'Arvo', serif;
    height: fit-content;
    width: 34vw;
    padding: 1vw;
    background: gainsboro;
    border: 2px solid rgba(255,255,0,.8);
    border-radius: 5%;
}
.img{
    background-color: gainsboro;
    height: 5vh;
    max-width: 5vw;
    margin: 4px auto;
}
.label {
    width: 21vw;
}
.contact h3{
    text-shadow: none;
    word-break: break-all;
}
.label p{
    margin-top: 0px;
    margin-bottom: 10px;
    color: black;
    text-decoration: none;
}
@media (max-width:800px) {
    .img{
        max-width: none;
        width: 12vw;
        height: auto;
    }
    #wrapper{
        max-width: 100vw;
    }
    .contact{
        flex-direction: column;
        flex-wrap: wrap;
        width:70vw;
    }
    #content h1{
        width: fit-content;
    }
    .contact h3{
        width: 65vw;
        left: 0vw;
    }
    .label,.contact h2,.contact p{
        width: 65vw;
    }

}
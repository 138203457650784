#planspage{
    width: 95vw;
    margin: auto;
    height: fit-content;
    padding: 3vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    background-color: white;
    border: 2px solid black;
    border-radius: 1%;
}
.plan{
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    border-radius: 5%;
    padding: 10px;
    background-color: gainsboro;
    border: 2px solid black;
}
.plan img{
    margin: auto;
}
.plan h2{
    width: 25vw;
    margin:  0 auto;
    margin-top: 2vh;
    text-decoration: underline;
}

.plan h3{
    margin: auto;
    margin-top: 2vh;
    width: 35vw;
    text-shadow: none;
}
.mainul{
    margin-top: 2vh;
}

.plan li{
    width: 15vw;
    text-align: left;
    font-size:large;
}
#acorn,#banna{
    width: 12vw;
}
#satimg{
    width: 20vw;
}
#gradepics{
    display: flex;
    flex-direction: column;
}
@media (max-width:800px) {
    #planspage{
        padding: 0;
        width: 100vw;
    }
    .plan{
        margin-top: 25px;
    }
    #satimg, .plan h2, .plan h3, .plan li, #acorn,#banna{
        width:70vw;
        color:black;
    }
     .plan h3, .plan li #acorn{
        margin: 0 0;
    }

    .plan li{
        width: 50vw;
    }
}